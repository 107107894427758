<template>
  <div>
    <el-button  class="dudao" type="text" @click="open">督导退出</el-button>
    <div class="tou">
      <div>
        <button @click="classname()">查看所有园名称</button>
      </div>
      <div>
        省份
        <select v-model="province1" name="province" @change="change_select">
          <option value="">请选择省市</option>
          <option v-for="(item, index) in shengfen" :key="index">
            {{ item.value }}
          </option>
        </select>
        地市
        <select v-model="city" name="cityName">
          <option value="">全部</option>
          <option v-for="(item, index) in cityName" :key="index">
            {{ item.value }}
          </option>
        </select>
      </div>
      <div>
        <button>选择</button>
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        style="width: 100%"
        border="true"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="sheng" label="省份"> </el-table-column>
        <el-table-column prop="diqu" label="地市"> </el-table-column>
        <el-table-column prop="schoolname" label="已申报园所数量">
        </el-table-column>
      </el-table>
      <div class="fenye">
        <el-pagination background layout="prev, pager, next" :total="100">
        </el-pagination>
      </div>
    </div>
    <div class="home">
      <button  class="home" @click="home()">首页</button>
    </div>
  </div>
</template>

<script>
import { options } from "../../assets/js/city";
export default {
  name: "homeIndex",
  mounted() {},

  data() {
    return {
      shengfen: options[0].children,
      province1: "",
      cityName: "",
      city: "",
      multipleSelection: [],
      tableData: [
        {
          sheng: "北京",
          diqu: "丰台区",
          schoolname: "北京校足办",
        },
        {
          sheng: "北京",
          diqu: "丰台区",
          schoolname: "北京校足办",
        },
      ],
    };
  },
  created() {
    this.init();
  },
  props: ["data_obj"],
  methods: {
    init() {
      console.log("options", this.shengfen);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
     open() {
        this.$confirm('确定退出督导组账号', '督导组', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$router.push("/home")
          this.data_obj.flag = true
          this.$message({
            type: 'success',
            message: '退出成功!',
            
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
    change_select() {
      var that = this;
      that.cityName = "";
      for (var k in that.shengfen) {
        if (this.province1 == that.shengfen[k].value) {
          that.cityName = that.shengfen[k].children;
        }
      }
    },
    home(){
      this.$router.push("/unfairness");
    },
    classname() {
      this.$router.push("/unfairnessInfo");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/xiugai.css";
.content {
  width: 70%;
  margin: 0 auto;
  color: #000;
}
.tou {
  width: 90%;
  margin: 30px auto;
  display: flex;
  flex: 1;
  justify-content: space-around;

  div {
    line-height: 50px;
    select {
      margin: 0 10px 0 10px;
    }
    button {
      width: 150px;
      height: 50px;
      border: none;
      padding: 0 5px;
      border-radius: 25px;
      background: #00CFE2;
      color: #fff;
    }
  }
}
.dudao{
  width: 270px;
  height: 70px;
  position:  relative;
  top: -540px;
  right:-1585px;
  color: #fff;
  background: #169BD5;
  border-radius: 15px;
  font-size: 20px;
}
.fenye {
  text-align: center;
  margin-top: 20px;
}
.home {
  width: 0.8rem;
  height: 0.5rem;
  position: fixed;
  bottom: 20vh;
  right: 0;
  color: #fff;
  z-index: 999;
  background: #169BD5;
  border: none;
   border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
}
</style>